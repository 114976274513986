<template>
    <div>
        <h1 class="title is-4">
            <template>{{ $t("Interface.Door.Edit.Title") + ` "${Form.name}"` }}</template>
        </h1>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }"
            tag="form"
            class="form box"
        >
            <div class="columns is-multiline">
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Form.name"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Door.Name')"
                                type="text"
                                data-cy="Name"
                                required
                            >
                            <label class="label">
                                {{ $t("Interface.Door.Name") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Form.address"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Door.Address')"
                                type="text"
                                data-cy="Address"
                                required
                            >
                            <label class="label">
                                {{ $t("Interface.Door.Address") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Form.latitude"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Door.Latitude')"
                                type="text"
                                data-cy="Latitude"
                                required
                            >
                            <label class="label">
                                {{ $t("Interface.Door.Latitude") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field"
                    >
                        <div class="floating-control control has-icons-right">
                            <input
                                v-model="Form.longtitude"
                                class="input"
                                :class="{ 'is-danger': errors[0] }"
                                :name="$t('Interface.Door.Longtitude')"
                                type="text"
                                data-cy="Longtitude"
                                required
                            >
                            <label class="label">
                                {{ $t("Interface.Door.Longtitude") }}
                            </label>
                            <span v-if="valid" class="goto-icon icon is-right">
                                <i class="goto-icon-check" />
                            </span>
                        </div>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field is-floated is-margin-top-short"
                    >
                        <label class="label">
                            {{ $t("Interface.Door.DoorType") }}
                        </label>
                        <b-select v-model="Form.doorType" expanded>
                            <option
                                v-for="AvailableType in AvailableTypes"
                                :key="AvailableType.type"
                                :value="AvailableType.type"
                            >
                                {{ AvailableType.name }}
                            </option>
                        </b-select>
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
                <div class="column is-half">
                    <ValidationProvider
                        v-slot="{ valid, errors }"
                        tag="div"
                        rules="required"
                        class="form-field field is-floated is-margin-top-short"
                    >
                        <label class="label">
                            {{ $t("Interface.Door.Timezone") }}
                        </label>
                        <b-numberinput
                            v-model="Form.timeZone"
                            :class="{ 'is-danger': errors[0] }"
                            :name="$t('Interface.Door.Timezone')"
                            data-cy="Timezone"
                            required
                            type="is-light"
                            min="-12"
                            max="14"
                        />
                        <p v-if="errors[0]" class="help is-danger">
                            <span class="goto-icon icon is-small is-right">
                                <i class="goto-icon-error" />
                            </span>
                            {{ errors[0] }}
                        </p>
                    </ValidationProvider>
                </div>
            </div>

            <hr>
            <uploader
                :files="FileList"
                title="Фотографии"
                :url="AttachImageURL"
                :headers="Header"
                limit="10"
                @on-delete="deleteImage"
                @on-success="successUploadImage"
            />
            <br>

            <button
                type="submit"
                class="button is-theme"
                :disabled="invalid"
                @click.prevent="handleSubmit(submit)"
            >
                {{ $t("Interface.Button.Submit") }}
            </button>
        </ValidationObserver>
        <b-loading
            :is-full-page="true"
            :active.sync="IsLoading"
            :can-cancel="false"
        />
    </div>
</template>

<script>
import Uploader from "vux-uploader-component"
import LoadingState from "@/mixins/LoadingState"
import {
    DOOR_LIST_REQUEST,
    DOOR_EDIT_REQUEST,
    DOOR_IMAGE_DELETE_REQUEST
} from "@/store/actions/door"

export default {
    name: "DoorEdit",
    components: {
        Uploader
    },
    mixins: [LoadingState],
    data() {
        return {
            Form: {
                name: null, // название двери
                doorType: null, // тип двери
                address: null, // адрес расположения двери
                latitude: 0.0, // координаты двери
                longtitude: 0.0,
                timeZone: 0 // смещение часового пояса
            },
            FileList: [] // список изображений
        }
    },
    computed: {
        AvailableTypes() {
            return [
                {
                    type: 0,
                    name: this.$t("Interface.Door.Type.0")
                },
                {
                    type: 1,
                    name: this.$t("Interface.Door.Type.1")
                },
                {
                    type: 2,
                    name: this.$t("Interface.Door.Type.2")
                }
            ]
        },
        Door: {
            get() {
                return this.$store.getters.GET_DOOR_BY_ID(parseInt(this.$route.params.id, 10))
            },
            set() {
                return null
            }
        },
        AttachImageURL() {
            return `${process.env.VUE_APP_API_AUTH_URL}door/${this.$route.params.id}/image`
        },
        Header() {
            return {
                Authorization: this.$store.state.Auth.Token
            }
        }
    },
    mounted() {
        if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
            if (!this.$store.getters.IS_DOORS_LOADED) {
                this.switchLoading()
                this.$store.dispatch(DOOR_LIST_REQUEST)
                    .then(() => this.getData())
                    .finally(() => this.switchLoading())
            }
            else this.getData()
        }
    },
    methods: {
        getData() {
            this.Form.name = this.Door.name
            this.Form.doorType = this.Door.doorType
            this.Form.address = this.Door.address
            this.Form.latitude = this.Door.latitude
            this.Form.longtitude = this.Door.longtitude
            this.Form.timeZone = this.Door.timeZone
            this.setLocalFileList(this.Door.images)
        },
        submit() {
            this.switchLoading()
            this.$store.dispatch(DOOR_EDIT_REQUEST, { Form: this.Form, Id: this.$route.params.id })
                .then(() => {
                    this.$store.dispatch(DOOR_LIST_REQUEST)
                        .then(() => this.$router.push({ name: "Door-List" }))
                })
                .finally(() => this.switchLoading())
        },
        successUploadImage(result, fileItem) {
            const NewItem = fileItem
            NewItem.id = result.doorImages[0].id // прописываем id для возможности удаления
            return NewItem
        },
        deleteImage(cb, callback) {
            this.$store.dispatch(DOOR_IMAGE_DELETE_REQUEST, cb.id)
                .then((result) => {
                    callback(result) // закрывает окно просмотрщика
                })
        },
        setLocalFileList(value) {
            this.FileList = Object.assign([], value) // для предотвращения изменений хранилища без мутации
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Door.Edit.Title")
        }
    }
}
</script>
